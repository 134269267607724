import React from "react"
import Img from "gatsby-image"
import { Layout } from "../components/layout"
import { useMaxItemHeight } from "../components/hooks"

const heightCSS = `calc(100%)`

export default ({ data }) => {
  const maxHeightCSS = useMaxItemHeight()
  return (
    <Layout>
      <div className="grid grid-cols-1 grid-flow-row overflow-hidden">
        {data.allFile.nodes.map(node => {
          console.log(node.childImageSharp.fluid)
          return (
            <div
              style={{
                height: "100%",
                width: "100%",
                position: "relative",
              }}
            >
              <Img
                key={node.childImageSharp.id}
                fluid={node.childImageSharp.fluid}
                imgStyle={{ objectFit: "contain" }}
                style={{
                  width: "100%",
                  maxHeight: maxHeightCSS,
                  height: heightCSS,
                  maxWidth: "100%",
                  zIndex: 1,
                }}
              />
              <Img
                key={node.childImageSharp.id}
                fluid={node.childImageSharp.fluid}
                imgStyle={{ objectFit: "contain" }}
                style={{
                  width: "100%",
                  maxWidth: "100%",
                  position: "absolute",
                  left: "0px",
                  top: "0px",
                  zIndex: 0,
                  filter: "blur(20px) contrast(.6)",
                }}
              />
            </div>
          )
        })}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query MyQuery {
    allFile(filter: { relativeDirectory: { eq: "images/portfolio" } }) {
      nodes {
        childImageSharp {
          fluid(quality: 100, maxWidth: 3840) {
            base64
            tracedSVG
            srcWebp
            srcSetWebp
            originalImg
            originalName
            presentationWidth
            presentationHeight
            srcSet
            src
            sizes
            aspectRatio
          }
        }
      }
    }
  }
`
